import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { Col } from 'components/Layout/Grid';

import { smallTablet } from 'helper/constants/mediaRules';
import { FONT_SIZE_LANDING_HEADER_TITLE, FONT_SIZE_36 } from 'helper/constants/styles';
import strings from 'helper/localization/localization';

import SEO from 'components/Seo';

export const HeaderCol = styled(Col)`
  padding: 0;
  text-align: center;
  margin-top: 6rem;
  align-self: center;

  ${smallTablet(css`
    margin-top: 10rem;
    padding: 0;
  `)}

  > h1 {
    font-size: ${FONT_SIZE_36};

    ${smallTablet(css`
      font-size: ${FONT_SIZE_LANDING_HEADER_TITLE};
    `)}
  }
`;

const SuccessBody = styled.h3`
  margin-top: 2.4rem;
  line-height: 2.4rem;
  padding: 0 22px;
`;

const BodyLinks = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ContactSuccess = () => (
  <>
    <SEO title="Success!" />
    <HeaderCol id="qa-contact-success-page" width={[1, 1, 0.84, 0.66]}>
      <h1>{strings.formSuccess}</h1>
      <h1>{strings.contactSuccessTitle}</h1>
      <SuccessBody>
        {strings.contactSuccessBody}
        {' '}
        <BodyLinks id="qa-success-docslink" to="/documentation">{strings.sdkDocumentation}</BodyLinks>
        {'.'}
      </SuccessBody>
    </HeaderCol>
  </>
);

export default ContactSuccess;
